// import {$, jQuery} from 'jquery';
import 'jquery-ui'
import '../../js/plugins/multiSelect'
import instantiator from '../_instantiator'
import { map } from 'lodash'

$(() => {
  window.$tabs = new Map(map(app.DOM.tabs, (t) => {
    const tabClass = '.' + t.classList[1];
    const jsClassName = tabToClassName(tabClass);
    return [jsClassName, instantiator(jsClassName, $(`div${tabClass}`))]
  }))

  if (app.URI[4]) {

    $(`a.tab-${app.URI[4]}`).click()
    const tabObject = tabToClassName('.tab-' + app.URI[4])
    window.$tabs.get(tabObject).triggerRemoteRebuildTables()
  }
})